import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { currency, unit, nameToSlug } from "./helpers";

// generate produce items invoice PDF
export const produceInvoice = (props) => {
  const { number, date, name = "All Locations", items, expense = 0 } = props;

  const header = ["#", "Name", "Qty", "Price", "GST", "Amount"];
  const lineItems = [];
  const orderTotal =
    items.reduce((total, item) => total + item.amounts.total, 0) + expense;

  const filteredItems = items.filter((item) => item.quantities.delivered > 0);

  if (filteredItems.length === 0) return;

  // sort items by group (alphabetically) and then by name (alphabetically)
  filteredItems.sort((a, b) => {
    if (a.group < b.group) return -1;
    if (a.group > b.group) return 1;
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  filteredItems.forEach((item, index) => {
    const itemData = [
      { content: index + 1 },
      { content: `${item.name} (${item.unit})` },
      { content: unit(item.quantities?.delivered ?? 0) },
      { content: currency(item.amounts?.average ?? 0) },
      { content: currency(0) },
      { content: currency(item.amounts?.total) },
    ];

    lineItems.push(itemData);
  });

  if (expense > 0)
    lineItems.push([
      // { content: "Expenses", colSpan: 4, styles: { halign: "right" } },
      { content: items.length + 1 },
      { content: "Expenses" },
      { content: 1 },
      { content: currency(expense) },
      { content: currency(0) },
      { content: currency(expense) },
    ]);

  const doc = new jsPDF();

  const alignColumn = (data) => {
    const col = data.column.index;
    if (col === 0 || col === 1) {
      data.cell.styles.cellWidth = "wrap";
      data.cell.styles.minCellWidth = 50;
    }
    if (col === 2) {
      data.cell.styles.halign = "center";
    }
    if (col === 3 || col === 4 || col === 5) {
      data.cell.styles.halign = "right";
    }
  };

  autoTable(doc, {
    body: [
      [
        {
          content: "Radhe Wholesale & Retail Pty Ltd",
          styles: { halign: "left", fontSize: 20, textColor: "#ed3137" },
        },
        {
          content: "Invoice",
          styles: { halign: "right", fontSize: 20, textColor: "#ed3137" },
        },
      ],
    ],
    theme: "plain",
  });

  autoTable(doc, {
    body: [
      [{ content: `${name}`, colSpan: 2, styles: { halign: "left" } }],
      [
        { content: `Order ${number}`, styles: { halign: "left" } },
        { content: `Date: ${date}`, styles: { halign: "right" } },
      ],
    ],
    styles: { fontSize: 12, fontStyle: "bold" },
    theme: "grid",
  });

  autoTable(doc, {
    head: [header],
    body: [...lineItems],
    styles: { fontSize: 11 },
    headStyles: { fillColor: "#343a40" },
    columnStyles: {
      0: { halign: "left" },
      1: { halign: "left", fontStyle: "bold" },
      2: { halign: "center" },
      3: { halign: "right" },
      4: { halign: "right" },
      5: { halign: "right", fontStyle: "bold" },
    },
    didParseCell: (data) => {
      alignColumn(data);
    },
    theme: "grid",
  });

  autoTable(doc, {
    body: [
      [
        {
          content: "Subtotal:",
          styles: { halign: "right", fontStyle: "bold" },
        },
        {
          content: currency(orderTotal),
          styles: { halign: "right" },
        },
      ],
      [
        {
          content: "Total tax:",
          styles: { halign: "right", fontStyle: "bold" },
        },
        { content: currency(0), styles: { halign: "right" } },
      ],
      [
        {
          content: "Total amount:",
          styles: { halign: "right", fontStyle: "bold" },
        },
        {
          content: currency(orderTotal),
          styles: { halign: "right", fontStyle: "bold" },
        },
      ],
    ],
    styles: { fontSize: 12 },
    theme: "grid",
  });

  autoTable(doc, {
    body: [
      [
        {
          content: `This is a system generated (from Radhe Online Ordering System)\nand does not require signature.`,
          styles: {
            halign: "center",
            fontSize: 10,
            fontStyle: "bolditalic",
            textColor: "#4d4d4d",
          },
        },
      ],
    ],
    theme: "plain",
  });

  // add page number of total pages to footer in each page of the document (centered)
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(10);
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text(
      `Page ${i} of ${pageCount}`,
      doc.internal.pageSize.getWidth() / 2,
      doc.internal.pageSize.getHeight() - 10,
      { align: "center" }
    );
  }

  doc.save(`Inv-${number}-${nameToSlug(name)}.pdf`);
};

// generate produce supplier items list PDF
export const notifiedItemsList = (props) => {
  const { number, date, name = "All Suppliers", items } = props;

  const header = ["#", "Name", "Group", "Notified", "Collected", ""];
  const lineItems = [];

  const filteredItems = items.filter((item) => item.quantity > 0);

  if (filteredItems.length === 0) return;

  // sort items by group (alphabetically) and then by name (alphabetically)
  filteredItems.sort((a, b) => {
    if (a.thing.group < b.thing.group) return -1;
    if (a.thing.group > b.thing.group) return 1;
    if (a.thing.label < b.thing.label) return -1;
    if (a.thing.label > b.thing.label) return 1;
    return 0;
  });

  filteredItems.forEach((item, index) => {
    const itemData = [
      { content: index + 1 },
      { content: `${item.thing.label} (${item.thing.unit})` },
      { content: item.thing.group },
      { content: unit(item.quantity ?? 0) },
      { content: `     ` },
      { content: `               ` },
    ];

    lineItems.push(itemData);
  });

  const doc = new jsPDF();

  const alignColumn = (data) => {
    const col = data.column.index;
    if (col === 0 || col === 1 || col === 2) {
      data.cell.styles.cellWidth = "wrap";
      data.cell.styles.minCellWidth = 50;
    }
    if (col === 3 || col === 4) {
      data.cell.styles.halign = "center";
    }
  };

  autoTable(doc, {
    startY: 5,
    margin: { left: 9, right: 9, bottom: 3 }, // This sets the left and right margins
    body: [
      [
        {
          content: "Radhe Wholesale & Retail Pty Ltd",
          styles: {
            halign: "left",
            fontSize: 14,
            textColor: "#ed3137",
            fontStyle: "bold",
          },
        },
        {
          content: "Collection",
          styles: {
            halign: "right",
            fontSize: 14,
            textColor: "#ed3137",
            fontStyle: "bold",
          },
        },
      ],
    ],
    theme: "plain",
  });

  autoTable(doc, {
    startY: 15,
    margin: { left: 9, right: 9 },
    body: [
      [
        {
          content: `Supplier: ${name}`,
          colSpan: 2,
          styles: { halign: "left" },
        },
      ],
      [
        { content: `Order ${number}`, styles: { halign: "left" } },
        { content: `Date: ${date}`, styles: { halign: "right" } },
      ],
      [
        {
          content: `Collection by Operator: ______________________________________________________`,
          colSpan: 2,
        },
      ],
    ],
    styles: { fontSize: 12, fontStyle: "bold" },
    theme: "grid",
  });

  autoTable(doc, {
    margin: { left: 9, right: 9 },
    head: [header],
    body: [...lineItems],
    styles: { fontSize: 11 },
    headStyles: { fillColor: "#343a40" },
    columnStyles: {
      0: { halign: "left" },
      1: { halign: "left", fontStyle: "bold" },
      2: { halign: "left" },
      3: { halign: "center" },
      4: { halign: "center" },
      5: { halign: "left", minCellWidth: 35 },
    },
    didParseCell: (data) => {
      alignColumn(data);
    },
    theme: "grid",
  });

  autoTable(doc, {
    margin: { left: 9, right: 9 },
    body: [
      [
        {
          content: `This is a system generated list (from Radhe Online Ordering System).`,
          styles: {
            halign: "center",
            fontSize: 10,
            fontStyle: "bolditalic",
            textColor: "#4d4d4d",
          },
        },
      ],
    ],
    theme: "plain",
  });

  // add page number of total pages to footer in each page of the document (centered)
  const pageCount = doc.internal.getNumberOfPages();
  doc.setFontSize(10);
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text(
      `Page ${i} of ${pageCount}`,
      doc.internal.pageSize.getWidth() / 2,
      doc.internal.pageSize.getHeight() - 10,
      { align: "center" }
    );
  }

  doc.save(`List-${number}-${nameToSlug(name)}.pdf`);
};
